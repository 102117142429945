import React, { useMemo } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { localeToCountry } from '@scentregroup/shared/helpers/local-to-country'
import { getDefaultNationalMetaData } from '@scentregroup/shared/helpers/get-default-national-metadata'
import { setCacheHeaders } from '@scentregroup/shared/helpers/set-cache-headers'
import { useGetHrefContext } from '@scentregroup/shared/context'
import Layout from '@scentregroup/shared/hub-components/layout'
import { SearchAction, WebSite, WithContext, Organization } from 'schema-dts'
import { SGLinkedData } from '@scentregroup/shared/hub-components/json-ld'
import { getTopStripProps } from '@scentregroup/shared/hub-components/top-strip'
import { completelyCanonicalUrl } from '@scentregroup/shared/helpers/canonical-link'
import { Template } from '@scentregroup/shared/components/page-templates/template'
import {
  fetchNationalStaticData,
  NationalStaticData,
} from '@scentregroup/shared/helpers/fetch-header-static-data'
import { GetServerSideProps, GetServerSidePropsContext } from '../types/next'
import { makeApolloClient } from '../lib/apolloClient'
import { getNationalHomepage } from '../lib/page-templates/fetching'
import { useQuickLinksFromTemplate } from '../components/page-templates/use-quicklinks-from-template'

function useLDWebSite(url: string): WithContext<WebSite> {
  return useMemo<WithContext<WebSite>>(
    () => ({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      '@id': `${url}#website`,
      name: 'Westfield',
      url,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${url}search?q={search_term_string}&utm_source=google&utm_medium=organic&utm_campaign=sitelinks_searchbox`,
        'query-input': 'required name=search_term_string',
      } as SearchAction & { 'query-input': string }, // schema-dts doesn't support extension properties at the moment
    }),
    [url]
  )
}

function useLDOrganisation(
  url: string,
  country: string
): WithContext<Organization> {
  return useMemo<WithContext<Organization>>(
    () => ({
      url,
      '@context': 'https://schema.org',
      '@type': 'Organization',
      '@id': `${url}#organisation`,
      name: 'Westfield',
      logo: 'https://d2csxpduxe849s.cloudfront.net/media/8AB6B2CD-D538-46A4-930B472438784815/EED72F45-87BB-497F-B857D5E512F9EB15/webimage-5FD64FB6-CF64-435C-B838EDF6ABCD6C72.png',
      alternateName: 'Scentre Group',
      legalName: 'SCENTRE LIMITED',
      foundingDate: '19590701',
      founders: [
        {
          '@type': 'Person',
          name: 'Frank Lowy',
        },
        {
          '@type': 'Person',
          name: 'John Saunders',
        },
      ],
      ...(country === 'New Zealand'
        ? {
            address: {
              '@type': 'PostalAddress',
              streetAddress: '277 Broadway',
              addressLocality: 'Newmarket',
              addressRegion: 'Auckland',
              postalCode: '1023',
              addressCountry: 'NZ',
            },
            sameAs: [
              'https://www.facebook.com/WestfieldAUNZ',
              'https://www.instagram.com/westfieldnz/',
              'https://www.youtube.com/@westfieldnz',
              'https://www.linkedin.com/company/scentre-group/',
            ],
            potentialAction: {
              '@type': 'SearchAction',
              target:
                'https://www.westfield.co.nz/search?q={search_term}&utm_source=google&utm_medium=organic&utm_campaign=sitelinks_searchbox',
              'query-input': 'required name=search_term',
            },
          }
        : {
            address: {
              '@type': 'PostalAddress',
              streetAddress: '85 Castlereagh St',
              addressLocality: 'Sydney',
              addressRegion: 'NSW',
              postalCode: '2000',
              addressCountry: 'AU',
            },
            sameAs: [
              'https://www.facebook.com/WestfieldAUNZ',
              'https://www.instagram.com/westfieldau/',
              'https://www.pinterest.com.au/westfieldau/',
              'https://www.youtube.com/@WestfieldAu',
              'https://www.linkedin.com/company/scentre-group/',
            ],
            potentialAction: {
              '@type': 'SearchAction',
              target:
                'https://www.westfield.com.au/search?q={search_term}&utm_source=google&utm_medium=organic&utm_campaign=sitelinks_searchbox',
              'query-input': 'required name=search_term',
            },
          }),
    }),
    [url, country]
  )
}

const RssFeeds: React.FC<React.PropsWithChildren> = () => {
  const router = useRouter()

  const feeds = useMemo(() => {
    const domain = router.domainLocales?.find(
      l => l.defaultLocale === router.locale
    )?.domain
    return ['news', 'stories', 'events', 'offers'].map(feed =>
      completelyCanonicalUrl(`${domain}/rss/${feed}`)
    )
  }, [router.domainLocales, router.locale])
  return (
    <Head>
      {feeds.map(link => (
        <link
          key={link}
          rel="alternate"
          type="application/rss+xml"
          href={link}
        />
      ))}
    </Head>
  )
}

type NationalHomePageProps = {
  country: 'Australia' | 'New Zealand'
  nationalHeaderData: NationalStaticData
  template: NonNullable<Awaited<ReturnType<typeof getNationalHomepage>>>
  topStripProps: Awaited<ReturnType<typeof getTopStripProps>>
}

export const getServerSideProps: GetServerSideProps<
  NationalHomePageProps
> = async ({ locale, res }: GetServerSidePropsContext) => {
  const client = makeApolloClient()
  const country = localeToCountry(locale)

  const [nationalHeaderData, template, topStripProps] = await Promise.all([
    fetchNationalStaticData(client, { country }),
    getNationalHomepage(client, country),
    getTopStripProps(client, country),
  ])

  if (!nationalHeaderData || !template) {
    setCacheHeaders(res, 'no-cache')
    return {
      notFound: true,
    }
  }

  setCacheHeaders(res)
  return {
    props: {
      country,
      nationalHeaderData,
      template,
      topStripProps,
    },
  }
}

const NationalHomePage: React.FC<
  React.PropsWithChildren<NationalHomePageProps>
> = props => {
  const { country, nationalHeaderData, topStripProps } = props

  const [template, quickLinks] = useQuickLinksFromTemplate(props.template)

  const { title, shortDescription } = template
  const canonical = completelyCanonicalUrl(useGetHrefContext()())

  const ldJsonSchemaForWebSite = useLDWebSite(canonical)

  const ldJsonSchemaForOrganisation = useLDOrganisation(canonical, country)

  const defaultMetaData = getDefaultNationalMetaData({
    country,
    type: 'homepage',
  })

  const metaData = {
    title: title || defaultMetaData.title,
    description: shortDescription || defaultMetaData.description,
    canonical,
  }

  return (
    <>
      <SGLinkedData item={ldJsonSchemaForWebSite} />
      <SGLinkedData item={ldJsonSchemaForOrganisation} />
      <RssFeeds />
      <Layout
        country={country}
        topStrip={topStripProps}
        quickLinks={quickLinks}
        header={{
          variant: 'national',
          data: nationalHeaderData,
        }}
        metaData={metaData}
      >
        <Template template={template} />
      </Layout>
    </>
  )
}

export default NationalHomePage
